// always keep CssBaseline at the top, it is used by Box just below it
import CssBaseline from "@mui/material/CssBaseline"
import { Suspense, lazy, useEffect } from "react"
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom"

import Box from "@mui/material/Box"
import { ThemeProvider } from "@mui/material/styles"

import { appTheme } from "./app/theme"
import AppMenu from "./components/AppMenu/AppMenu"
import Footer from "./components/Footer"
import RootBoundary from "./components/ErrorBoundary/RootBoundary"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import AppBar from "@mui/material/AppBar"
import { BRAND_COLORS, TOP_OF_PAGE_ID } from "./app/constants"
import GoToBottom from "./components/Scroller/GoToBottom"
import GoToTop from "./components/Scroller/GoToTop"

const MemePage = lazy(() => import("./components/MemePage/MemePage"))
const FeedPage = lazy(() => import("./components/FeedPage/FeedPage"))
const AugmentPage = lazy(() => import("./components/AugmentPage/Page"))
const YouTubePage = lazy(() => import("./components/YouTubePage/YouTubePage"))

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: "meme",
        element: <MemePage />,
      },
      {
        path: "feed",
        element: <FeedPage />,
      },
      {
        path: "augment",
        element: <AugmentPage />,
      },
      {
        path: "yclip",
        element: <YouTubePage />,
      },
    ],
  },
])

function Root() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === "/") {
      navigate("/feed")
    }
  })

  useEffect(() => {
    document.title = `Speeedr! ${pathname.slice(1)}`
  }, [pathname])

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Box
        id={TOP_OF_PAGE_ID}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          bgcolor: "background.default",
        }}
      >
        <AppMenu />
        <Outlet />
        <Footer />
      </Box>
      <GoToBottom />
      <GoToTop />
    </ThemeProvider>
  )
}

function LoadingFallback() {
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ height: "50px", bgcolor: BRAND_COLORS.GOLD }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default function App() {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <RouterProvider router={router} />
    </Suspense>
  )
}
