import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import {
  AppBar,
  Button,
  Container,
  Link,
  Toolbar,
  Typography,
} from "@mui/material"
import { NavLink } from "react-router-dom"
import { TOP_OF_PAGE_ID } from "../../app/constants"

type LinkData = {
  name: string
  link: string
}

export default function AppMenu() {
  const links: LinkData[] = [
    { name: "Meme", link: "/meme" },
    { name: "Feed", link: "/feed" },
    { name: "Upload", link: "/augment" },
  ]

  return (
    <AppBar position="fixed" color="secondary" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component={Link} href="/" sx={{ textDecoration: "none" }}>
            <Avatar
              alt="Orijtech Logo"
              src="/logos/orijtech.png"
              sx={{ width: 36, height: 36 }}
            />
          </Box>
          <Typography
            component={Link}
            href="/"
            variant="body1"
            sx={{ textDecoration: "none", ml: 0.5 }}
          >
            Speeedr!
          </Typography>
          <Box sx={{ display: "flex", ml: "auto" }}>
            {links.map((l) => (
              <NavLinker data={l} key={l.name} />
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

interface LinkerProps {
  data: LinkData
}

function NavLinker(props: LinkerProps) {
  const { data } = props

  return (
    <NavLink
      to={data.link}
    >
      {({ isActive }) => (
        <Button
          sx={{
            fontWeight: isActive ? 600 : 400,
            bgcolor: isActive ? "secondary.dark" : "",
            textDecoration: "none",
          }}
        >
          {data.name}
        </Button>
      )}
    </NavLink>
  )
}
